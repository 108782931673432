const CardImages = {
  activitiesCompleted: "dashboard",
  wordsLearned: "sac_words",
  phonicsLearned: "sac_words",
  storiesNarrated: "book",
  rhymesReadAloud: "rhyme",
};

const CardDescriptions = {
  activitiesCompleted: "Activities Completed",
  wordsLearned: "Words Learned",
  phonicsLearned: "Phonics Learned",
  storiesNarrated: "Stories Narrated",
  rhymesReadAloud: "Rhymes Read Aloud",
};

const CategoryValues = {
  rhymes: "Rhymes",
  shapes: "Shapes",
  letters: "Letters",
  consonants: "Phonics",
  colors: "Colors",
  numbers: "Numbers",
};

export { CardImages, CardDescriptions, CategoryValues };
